import { ANIMATIONS } from '@js/constants/resourcesPaths';
import {
  FLOW_V2_ANIMATION_FILE_URLS_EXTEND,
  VOI_V2_ANIMATION_FILE_URLS_EXTEND
} from '@spotMobileConfig';
import Animation from './Animation';

export default Animation;

export const FLOW_V2_ANIMATION_FILE_URLS = {
  ...ANIMATIONS.FLOW_V2,
  ...FLOW_V2_ANIMATION_FILE_URLS_EXTEND
};

export const VOI_V2_ANIMATION_FILE_URLS = {
  ...ANIMATIONS.VOI_V2,
  ...VOI_V2_ANIMATION_FILE_URLS_EXTEND
};
