import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import information from './reducers/information';
import capture from './reducers/capture';
import appConfig from './reducers/appConfig';
import voiFlowV2 from './reducers/voiFlowV2';
import edited from './reducers/edited';
import { ipInfoReducer as ipInfo } from './reducers/ipInfo';
import { RESET_ALL_STORE } from './actions/reset';

const appReducer = combineReducers({ information, capture, appConfig, voiFlowV2, edited, ipInfo });

const rootReducer = (state, action) => {
  if (action.type === RESET_ALL_STORE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

let composeEnhancers = compose;
// eslint-disable-next-line n/no-process-env
if (process.env.NODE_ENV !== 'production') {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      serialize: true
    });
  }
}

export function setupStore(preloadedState) {
  return createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(thunk)));
}
