import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import camelCase from 'lodash/camelCase';
import parse from 'html-react-parser';
import Animation, { FLOW_V2_ANIMATION_FILE_URLS } from '@components/Animation';
import { CountrySelect } from '@lib/components/v2/Form';
import { getCountryLabelFromIso2Code } from '@lib/countryUtils';
import { localizedString, isLocalizedStringDefined } from '@languages';
import {
  FLOW_V2_ALLOW_MANUAL_ENTRY,
  FLOW_V2_ID_SELECTION_SHOW_ACCEPTABLE_DOCS,
  FLOW_V2_LOADING_DETAILS_PROGRESS_BAR,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_COUNTRY_LABEL,
  FLOW_V2_ACCEPTABLE_DOCS_IP_COUNTRY_SELECTED
} from '@spotMobileConfig';
import { LoadingBar } from '../..';
import classes from './IdSelection.style.module.scss';
import iso3 from '../../../../data/iso3.json';

const IdSelection = ({
  engine4Config = {},
  ipCountryCode = '',
  acceptableDocs = false,
  onShowAcceptableDocsClick,
  isLoading = false,
  idType,
  progressBar,
  onShowCaptureDigitalIdScreen = () => {},
  onSelectManualEntry = () => {}
}) => {
  const { acceptedCountries, acceptedDocs, acceptedDocCountryCombo } = engine4Config;

  const [country, setCountry] = useState(() => {
    if (FLOW_V2_ACCEPTABLE_DOCS_IP_COUNTRY_SELECTED && ipCountryCode) {
      return {
        value: ipCountryCode,
        label: getCountryLabelFromIso2Code(ipCountryCode)
      };
    }

    return {
      value: DEFAULT_COUNTRY_CODE,
      label: DEFAULT_COUNTRY_LABEL
    };
  });

  let cardTypes = [localizedString('driverLicence'), localizedString('passport')];

  if (acceptedDocs && acceptedDocs.length > 0) {
    cardTypes = acceptedDocs;
  }

  if (acceptedDocCountryCombo && Object.keys(acceptedDocCountryCombo).length > 0) {
    const temp = [];
    const iso3Country = iso3[country.value];
    Object.keys(acceptedDocCountryCombo).forEach((key) => {
      if (
        acceptedDocCountryCombo[key] !== 'NONE' &&
        (acceptedDocCountryCombo[key] === 'ALL' ||
          acceptedDocCountryCombo[key].includes(iso3Country))
      ) {
        const camelCasedDocKey = camelCase(key);
        const displayCardName = isLocalizedStringDefined(camelCasedDocKey)
          ? localizedString(camelCasedDocKey)
          : key;
        temp.push(displayCardName);
      }
    });
    cardTypes = temp;
  }

  const isDigitalIdEnabled = !!cardTypes.find((type) => type.match(/digital/i));

  if (isDigitalIdEnabled) {
    cardTypes = cardTypes.filter((type) => !type.match(/digital/i));
  }

  const handleCountryChange = (val) => {
    setCountry(val);
  };

  return (
    <div style={{ height: '100%' }}>
      {!acceptableDocs && !isLoading && (
        <div className={classes.wrapper}>
          <div className={classes.heading} data-testid="cptrId-heading">
            {localizedString('idSelection.FLOW_V2_ID_SELECTION_HEADING')}
          </div>
          <div className={classes.description} data-testid="cptrId-instruction">
            {parse(localizedString('idSelection.FLOW_V2_ID_SELECTION_DESCRIPTION'))}
          </div>
          <div className={classes['animation-container']} data-testid="cptrId-animation">
            <Animation
              animationUrl={
                idType !== 'PASSPORT'
                  ? FLOW_V2_ANIMATION_FILE_URLS.CARD
                  : FLOW_V2_ANIMATION_FILE_URLS.PASSPORT
              }
            />
          </div>

          <table className={classes.link_list}>
            <tbody>
              {FLOW_V2_ID_SELECTION_SHOW_ACCEPTABLE_DOCS && (
                <tr onClick={onShowAcceptableDocsClick} className={classes.link}>
                  <td>{localizedString('acceptableDocs')}</td>
                  <td>
                    <img alt="" src="images/icons/png/next-videoid.png" />
                  </td>
                </tr>
              )}
              {isDigitalIdEnabled && (
                <tr onClick={onShowCaptureDigitalIdScreen} className={classes.link}>
                  <td>{localizedString('idSelection.FLOW_V2_DIGITAL_ID_ACCEPTANCE_TITLE')}</td>
                  <td>
                    <img alt="arrow" src="images/icons/png/next-videoid.png" />
                  </td>
                </tr>
              )}
              {FLOW_V2_ALLOW_MANUAL_ENTRY && (
                <tr onClick={onSelectManualEntry} className={classes.link}>
                  <td>Enter details manually instead</td>
                  <td>
                    <img alt="arrow" src="images/icons/png/next-videoid.png" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {acceptableDocs && (
        <div className={classes.wrapper}>
          <div className={classes.heading} data-testid="accepted-heading">
            {localizedString('acceptedIdDocs')}
          </div>
          <div className={classes.accepted}>
            <CountrySelect
              filter={acceptedCountries}
              value={country}
              onChange={handleCountryChange}
            />
            <ul>
              {cardTypes.map((card, index) => {
                return (
                  <li key={card} data-testid={`accepted-doc${index}`}>
                    {card}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}

      {isLoading && (
        <div className={classes.wrapper}>
          <div className={classnames(classes.heading)} data-testid="id-heading">
            {localizedString('idSelection.FLOW_V2_LOADING_DETAILS_HEADING')}
          </div>
          <div className={classnames(classes.description)} data-testid="id-instruction">
            {parse(localizedString('idSelection.FLOW_V2_LOADING_DETAILS_DESCRIPTION'))}
          </div>
          <div className={classes['animation-container']} data-testid="id-animation">
            <Animation animationUrl={FLOW_V2_ANIMATION_FILE_URLS.REVIEW} />
          </div>
          {FLOW_V2_LOADING_DETAILS_PROGRESS_BAR && <LoadingBar width={progressBar} />}
        </div>
      )}
    </div>
  );
};

IdSelection.propTypes = {
  engine4Config: PropTypes.object,
  acceptableDocs: PropTypes.bool,
  onShowAcceptableDocsClick: PropTypes.func,
  isLoading: PropTypes.bool,
  idType: PropTypes.string,
  progressBar: PropTypes.number,
  onShowCaptureDigitalIdScreen: PropTypes.func,
  onSelectManualEntry: PropTypes.func,
  ipCountryCode: PropTypes.string
};
export default IdSelection;
