import React from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import parse from 'html-react-parser';
import { localizedString } from '@languages';

import classes from './Welcome.style.module.scss';

export default function Welcome({ onStart }) {
  const buttons = [
    {
      label: parse(localizedString('welcome.flowV2ButtonText')),
      onClick: () => {
        onStart();
      },
      dataTestId: 'welcome-button'
    }
  ];

  return (
    <Page buttons={buttons}>
      <div className={classes.wrapper}>
        <div className={classes.heading} data-testid="welcome-heading">
          {parse(localizedString('welcome.flowV2Title'))}
        </div>
        <div className={classes.description} data-testid="welcome-description">
          {parse(localizedString('welcome.flowV2Description'))}
        </div>
        <div className={classes.space} />
        <div className={classes.hyperlink} data-testid="welcome-hyperlink">
          {parse(localizedString('welcome.flowV2HyperlinkText'))}
        </div>
      </div>
    </Page>
  );
}

Welcome.propTypes = {
  onStart: PropTypes.func.isRequired
};
