import React from 'react';
import parse from 'html-react-parser';
import { localizedString } from '@languages';
import Message from '@lib/components/v2/Message';

/**
 * If card blurry or crop etc.
 */
export const Failed = (props) => {
  return (
    <Message
      {...props}
      title={localizedString('faceVerification.FLOW_V2_FAILED_ALERT_TITLE')}
      issue
    >
      {parse(localizedString('faceVerification.FLOW_V2_FAILED_ALERT_DESCRIPTION'))}
    </Message>
  );
};

/**
 * Enable Camera Permission
 */
export const Permission = (props) => {
  return (
    <Message
      {...props}
      title={localizedString('faceVerification.FLOW_V2_ENABLE_CAMERA_ALERT_TITLE')}
      issue
    >
      {localizedString('faceVerification.FLOW_V2_ENABLE_CAMERA_ALERT_DESCRIPTION')}
    </Message>
  );
};

/**
 * Enable camera settings
 */
export const CameraSettings = (props) => {
  return (
    <Message
      {...props}
      title={localizedString('faceVerification.FLOW_V2_CAMERA_SETTINGS_ALERT_TITLE')}
      issue
    >
      {parse(localizedString('faceVerification.FLOW_V2_CAMERA_SETTINGS_ALERT_DESCRIPTION'))}
    </Message>
  );
};

/**
 * Retry with alternative flow because of permission issue.
 */
export const PermissionIssueRequiresAlternativeFlow = (props) => {
  return (
    <Message
      {...props}
      title={localizedString('faceVerification.FLOW_V2_TRY_SOMETHING_ELSE_TITLE')}
      issue
    >
      {parse(localizedString('faceVerification.FLOW_V2_TRY_SOMETHING_ELSE_DESCRIPTION'))}
    </Message>
  );
};

/**
 * Device Incompatible
 */

export const Incompatible = (props) => {
  return (
    <Message {...props} issue title={localizedString('deviceIncompatibleContent')}>
      {localizedString('deviceIncompatibleDesc1')}
      <br />
      <br />
      {localizedString('deviceIncompatibleDesc2')}
    </Message>
  );
};

/**
 * Session Interrupted
 */

export const SessionInterrupted = (props) => {
  return (
    <Message {...props} issue title="Session interrupted">
      After attempting to reconnect, we are unable to continue to call.
      <br />
      <br />
      Please click below to try again and start another call.
    </Message>
  );
};

/**
 * Out of Hours
 */

export const OutOFHours = (props) => {
  return (
    <Message {...props} issue title="Out of hours">
      You will need to come back during business hours to complete the flow.
      <br />
      <br />
      9am to 5pm
      <br />
      Monday to Saturday
    </Message>
  );
};

/**
 * Unable to connect
 */

export const UnableToConnect = (props) => {
  return (
    <Message {...props} issue title="Unable to connect">
      After attempting to reconnect, we are unable to continue the call.
      <br />
      <br />
      Please click below to try again and start another call.
    </Message>
  );
};
