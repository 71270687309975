import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Page from '@lib/components/v2/Page';
import { localizedString } from '@languages';
import Animation, { FLOW_V2_ANIMATION_FILE_URLS } from '@components/Animation';
import { REDUCE_FACE_ANIMATION_SIZE, FLOW_V2_EXTRA_FR_TIPS } from '@spotMobileConfig';
import classes from './Prepare.style.module.scss';

const Prepare = ({ selfie = false, onStart, onGoBack }) => {
  let size = {};
  if (REDUCE_FACE_ANIMATION_SIZE && REDUCE_FACE_ANIMATION_SIZE > 0) {
    size = {
      width: `${100 - REDUCE_FACE_ANIMATION_SIZE}%`,
      margin: '0 auto'
    };
  }

  const buttons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: onGoBack,
      dataTestId: 'face-back'
    },
    {
      label: localizedString('continue'),
      type: 'submit',
      onClick: onStart,
      dataTestId: 'face-continue'
    }
  ];
  return (
    <Page hideLogo={false} buttons={buttons} className={classes.wrapper}>
      <div className={classes.heading} data-testid="face-heading">
        {localizedString('faceVerification.FLOW_V2_FR_TIPS_HEADING')}
      </div>
      <div className={classes.content} data-testid="face-instruction">
        {parse(
          selfie
            ? localizedString('faceVerification.FLOW_V2_FR_TIPS_SELFIE_DESCRIPTION')
            : localizedString('faceVerification.FLOW_V2_FR_TIPS_DESCRIPTION')
        )}

        <div
          style={size}
          className={classes['animation-container']}
          data-testid="face-instruction-img"
        >
          <Animation animationUrl={FLOW_V2_ANIMATION_FILE_URLS.FACE_ID} />
        </div>
        {FLOW_V2_EXTRA_FR_TIPS && <p>{FLOW_V2_EXTRA_FR_TIPS}</p>}
      </div>
    </Page>
  );
};

Prepare.propTypes = {
  onStart: PropTypes.func,
  onGoBack: PropTypes.func,
  selfie: PropTypes.bool
};

Prepare.defaultProps = {
  onStart: () => {}
};

export default Prepare;
