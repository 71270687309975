import React from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import parse from 'html-react-parser';
import { isLocalizedStringDefined, localizedString } from '@languages';

import classes from './Welcome.style.module.scss';

export default function Welcome({ onStart }) {
  const title = parse(localizedString('app.VOI_FLOW_V2_WELCOME_TITLE'));
  const description = parse(localizedString('app.VOI_FLOW_V2_WELCOME_DESCRIPTION'));
  const buttonText = parse(localizedString('app.VOI_FLOW_V2_WELCOME_BUTTON_TEXT'));

  const buttons = [
    {
      label: buttonText,
      onClick: () => {
        onStart();
      },
      dataTestId: 'welcome-button'
    }
  ];

  return (
    <Page buttons={buttons}>
      <div className={classes.wrapper}>
        <div className={classes.heading} data-testid="welcome-heading">
          {title}
        </div>
        <div className={classes.description} data-testid="welcome-description">
          {description}
        </div>
        <div className={classes.space} />
        {isLocalizedStringDefined('app.VOI_FLOW_V2_WELCOME_HYPERLINK_TEXT') && (
          <div className={classes.hyperlink} data-testid="welcome-hyperlink">
            {localizedString('app.VOI_FLOW_V2_WELCOME_HYPERLINK_TEXT')}
          </div>
        )}
      </div>
    </Page>
  );
}

Welcome.propTypes = {
  onStart: PropTypes.func.isRequired
};
