import PropTypes from 'prop-types';
import { localizedString } from '@languages';
import Message from '@lib/components/v2/Message';

export const UnderEligibleAgeMessage = ({ eligibleAge }) => {
  return (
    <Message issue title={localizedString('verifyDetails.UNDER_ELIGIBLE_AGE_TITLE', eligibleAge)}>
      <div>{localizedString('sessionCancelledDesc1')}</div>
      <br />
      <div>{localizedString('sessionCancelledDesc2')}</div>
    </Message>
  );
};
UnderEligibleAgeMessage.propTypes = {
  eligibleAge: PropTypes.number
};
