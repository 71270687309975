import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import { localizedString } from '@languages';
import Animation, { FLOW_V2_ANIMATION_FILE_URLS } from '@components/Animation';
import { FLOW_V2_LOADING_DETAILS_PROGRESS_BAR } from '@spotMobileConfig';
import { LoadingBar } from '@FLOW_V2_FLOW/components';
import Message from '@lib/components/v2/Message';
import APIs from '@services/APIs';
import classes from './ProofOfAddress.module.scss';

const STATUS = {
  IDLE: 'IDLE',
  UPLOADING: 'UPLOADING',
  ERROR_UPLOADING: 'ERROR_UPLOADING',
  GETTING_RESULT: 'GETTING_RESULT',
  ERROR_GETTING_RESULT: 'ERROR_GETTING_RESULT'
};

const ERROR_LOCALIZED_TITLE_KEYS = {
  ADDRESS_COULD_NOT_BE_FOUND: 'proofOfAddressError.ADDRESS_COULD_NOT_BE_FOUND_TITLE',
  ADDRESS_DOES_NOT_MATCH: 'proofOfAddressError.ADDRESS_DOES_NOT_MATCH_TITLE',
  DOC_COULD_NOT_BE_OPENED: 'proofOfAddressError.DOC_COULD_NOT_BE_OPENED_TITLE',
  NAME_DOES_NOT_MATCH: 'proofOfAddressError.NAME_DOES_NOT_MATCH_TITLE',
  THIS_IS_NOT_A_RECENT_DOC: 'proofOfAddressError.THIS_IS_NOT_A_RECENT_DOC_TITLE'
};

const ERROR_LOCALIZED_DESCRIPTION_KEYS = {
  ADDRESS_COULD_NOT_BE_FOUND: 'proofOfAddressError.ADDRESS_COULD_NOT_BE_FOUND_DESCRIPTION',
  ADDRESS_DOES_NOT_MATCH: 'proofOfAddressError.ADDRESS_DOES_NOT_MATCH_DESCRIPTION',
  DOC_COULD_NOT_BE_OPENED: 'proofOfAddressError.DOC_COULD_NOT_BE_OPENED_DESCRIPTION',
  NAME_DOES_NOT_MATCH: 'proofOfAddressError.NAME_DOES_NOT_MATCH_DESCRIPTION',
  THIS_IS_NOT_A_RECENT_DOC: 'proofOfAddressError.THIS_IS_NOT_A_RECENT_DOC_DESCRIPTION'
};

export const ProofOfAddress = ({ flowType, onBack, onNextStep }) => {
  const inputFileRef = useRef();
  const [currentStatus, setCurrentStatus] = useState(STATUS.IDLE);
  const [currentErrorType, setCurrentErrorType] = useState();

  const footerButtons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: onBack,
      dataTestId: 'capture-poa-back'
    },
    {
      label: localizedString('proofOfAddressCapture.CAPTURE_BUTTON_LABEL'),
      type: 'submit',
      onClick: openDeviceFiles,
      dataTestId: 'capture-poa-submit'
    }
  ];

  const loadingFooterButtons = [
    {
      label: '   ',
      variant: 'transparent'
    },
    {
      label:
        currentStatus === STATUS.UPLOADING
          ? localizedString('uploading')
          : localizedString('loading'),
      variant: 'transparent',
      loading: true,
      dataTestId: 'capture-poa-uploading'
    }
  ];

  const errorFooterButtons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: () => setCurrentStatus(STATUS.IDLE),
      dataTestId: 'capture-poa-back'
    },
    {
      label: localizedString('recapture'),
      type: 'submit',
      onClick: openDeviceFiles,
      dataTestId: 'capture-poa-recapture'
    }
  ];

  const isLoading = [STATUS.UPLOADING, STATUS.GETTING_RESULT].includes(currentStatus);

  return (
    <>
      <input
        type="file"
        name="image"
        accept="image/*"
        onChange={handleFileCapture}
        ref={inputFileRef}
        style={{ opacity: 0 }}
      />

      {[STATUS.IDLE, STATUS.UPLOADING, STATUS.GETTING_RESULT].includes(currentStatus) && (
        <Page buttons={isLoading ? loadingFooterButtons : footerButtons} forceFillViewPort>
          <div className={classes.wrapper}>
            <div className={classes.heading}>{localizedString('proofOfAddressCapture.TITLE')}</div>
            <div className={classes.description}>
              {localizedString('proofOfAddressCapture.DESCRIPTION')}
            </div>
            <div className={classes['animation-container']}>
              <Animation animationUrl={FLOW_V2_ANIMATION_FILE_URLS.PROOF_OF_ADDRESS} />
            </div>
            {isLoading && FLOW_V2_LOADING_DETAILS_PROGRESS_BAR && <LoadingBar width={100} />}
          </div>
        </Page>
      )}

      {[STATUS.ERROR_GETTING_RESULT, STATUS.ERROR_UPLOADING].includes(currentStatus) && (
        <Message
          buttons={errorFooterButtons}
          title={localizedString(
            ERROR_LOCALIZED_TITLE_KEYS[currentErrorType] ||
              ERROR_LOCALIZED_TITLE_KEYS.DOC_COULD_NOT_BE_OPENED
          )}
          issue
        >
          {localizedString(
            ERROR_LOCALIZED_DESCRIPTION_KEYS[currentErrorType] ||
              ERROR_LOCALIZED_DESCRIPTION_KEYS.DOC_COULD_NOT_BE_OPENED
          )}
        </Message>
      )}
    </>
  );

  function openDeviceFiles() {
    inputFileRef.current.click();
  }

  function handleFileCapture(e) {
    if (!e.target.files[0]) {
      return;
    }

    const imagefile = e.target.files[0];
    e.target.value = '';

    uploadImage(imagefile);
  }

  async function uploadImage(imagefile) {
    setCurrentStatus(STATUS.UPLOADING);

    const params = { frontFile: imagefile, flowType };
    let token;

    try {
      const response = await APIs.uploadImage(params, {}, '/api/v4');
      token = response.token;
      if (response.status === 'error') {
        throw response.msg;
      }
      if (!token) {
        throw new Error('No token returned');
      }
    } catch (error) {
      console.error(error);
      setCurrentStatus(STATUS.ERROR_UPLOADING);
      return;
    }

    setCurrentStatus(STATUS.GETTING_RESULT);

    let lastErrorType;
    try {
      const { status, errorType } = await APIs.extractIdentifyInfo(token, false, {
        isEngineV4: true,
        idType: 'Proof_of_Address',
        flowType
      });

      if (status === 'error') {
        lastErrorType = errorType;
        throw errorType;
      }

      onNextStep();
    } catch (error) {
      console.error(error);
      setCurrentErrorType(lastErrorType);
      setCurrentStatus(STATUS.ERROR_GETTING_RESULT);
    }
  }
};

ProofOfAddress.propTypes = {
  onBack: PropTypes.func,
  onNextStep: PropTypes.func,
  flowType: PropTypes.string
};
