import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DocumentListScreen } from '@lib/pages/v2/DocumentListScreen';
import { localizedString, isLocalizedStringDefined } from '@languages';

import {
  FLOW_V2_AVAILABLE_DOCS1,
  FLOW_V2_DATA_CHECK_DIFF_FILTER_PREVIOUS_USED_DOC,
  FLOW_V2_HIDE_IMAGES_IN_DOC_LIST
} from '@spotMobileConfig';

class ChooseAnotherID extends Component {
  static propTypes = {
    currentDoc: PropTypes.object,
    onNextStep: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      availableDocumentList: [],
      selectedDocument: null
    };

    this.handleNextStep = this.handleNextStep.bind(this);
  }

  componentDidMount() {
    const { currentDoc = {} } = this.props;
    const availableDocumentList = (FLOW_V2_AVAILABLE_DOCS1 ?? []).filter((doc1) => {
      return !FLOW_V2_DATA_CHECK_DIFF_FILTER_PREVIOUS_USED_DOC || doc1.cardType !== currentDoc.type;
    });
    this.setState({ availableDocumentList });
  }

  handleNextStep(e) {
    const { onNextStep } = this.props;
    const { selectedDocument } = this.state;
    if (e) {
      e.preventDefault();
    }
    if (selectedDocument) {
      onNextStep(selectedDocument);
    }
  }

  onCardClick(item, index) {
    if (item.checked) {
      return;
    }

    this.setState(({ availableDocumentList }) => ({
      availableDocumentList: availableDocumentList.map((card, i) => {
        return {
          ...card,
          checked: i === index
        };
      }),
      selectedDocument: availableDocumentList[index]
    }));
  }

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const { currentDoc = {} } = this.props;
    const { availableDocumentList, selectedDocument } = this.state;

    const footerButtons = [];
    footerButtons.push({
      label: localizedString('continue'),
      disabled: !selectedDocument,
      type: 'submit',
      onClick: this.handleNextStep,
      dataTestId: 'flow_v2_choose_another_id'
    });

    let description = `${localizedString('chooseDifferentIdInsteadOf')} ${
      currentDoc.title ||
      (isLocalizedStringDefined(currentDoc.type)
        ? localizedString(currentDoc.type)
        : currentDoc.type)
    }.`;

    if (isLocalizedStringDefined('chooseAnotherID.internationalPassportWithVisa')) {
      description += ` ${localizedString('chooseAnotherID.internationalPassportWithVisa')}`;
    }

    if (isLocalizedStringDefined('chooseAnotherID.CHOOSE_ANOTHER_ID_DESCRIPTION')) {
      description = localizedString('chooseAnotherID.CHOOSE_ANOTHER_ID_DESCRIPTION');
    }

    return (
      <DocumentListScreen
        title={localizedString('chooseAnotherID.CHOOSE_ANOTHER_ID_TITLE')}
        description={description}
        documentList={availableDocumentList}
        onClickDocumentItem={(item, index) => this.onCardClick(item, index)}
        footerButtons={footerButtons}
        hideDocImages={FLOW_V2_HIDE_IMAGES_IN_DOC_LIST}
      />
    );
  }
}

export default ChooseAnotherID;
