import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EVISA_CAPTURE_PHOTO } from '@spotMobileConfig';
import { Tips } from '@components';
import { localizedString } from '@languages';
import { CaptureVisaContent } from '../Contents';
import CaptureTips from './CaptureVisa.tips';
import classes from './CaptureVisa.style.module.scss';

export default class CaptureVisaP extends Component {
  static propTypes = {
    onSkip: PropTypes.func,
    onUploadVisa: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      showTip: false
    };
  }

  handleSkip = () => {
    this.props.onSkip();
  };

  handleUploadVisa = () => {
    this.props.onUploadVisa();
  };

  showTips = (val) => {
    this.setState({
      showTip: val
    });
  };

  render() {
    const tipsStates = {
      idType: 'VISA',
      tips: CaptureTips,
      onCapture: this.handleUploadVisa,
      lastButtonText: localizedString('captureIDNow')
    };

    const { showTip } = this.state;
    const buttons = [
      {
        label: localizedString('physicalVisa'),
        onClick: () => this.showTips(true),
        variant: 'outline'
      },
      {
        label: localizedString('eVisa'),
        onClick: () => (EVISA_CAPTURE_PHOTO ? this.showTips(true) : this.handleSkip()),
        variant: 'outline'
      }
    ];

    return (
      <div>
        {!showTip && (
          <div className={classNames(classes.wrapper, 'b-splash-black')}>
            <CaptureVisaContent buttons={buttons} />
          </div>
        )}
        {showTip && <Tips {...tipsStates} />}
      </div>
    );
  }
}
