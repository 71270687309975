import { useSelector } from 'react-redux';
import { LOGOS } from '@js/constants/resourcesPaths';

export const useLogo = ({ isSuccessScreen = false } = {}) => {
  const { APP_LOGO, APP_LOGO_SUCCESS } = LOGOS;

  const logo = useSelector(({ appConfig }) => appConfig?.logo);

  return logo || (isSuccessScreen ? APP_LOGO_SUCCESS : APP_LOGO);
};
