import axios from 'axios';
import APIs from '@services/APIs';

const { API_URL } = APIs;

/**
 * SMS Service
 */
export default class SMS {
  /**
   * Make a request to verify address.
   * @return {Promise}
   * @param data
   */
  static send(data) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    const transToken = document.querySelector('body').getAttribute('data-id');

    return axios
      .post(`${API_URL}/sms/resend/${transToken}`, data, { cancelToken: source.token })
      .then(({ data }) => data);
  }
}
