export const LOGOS = {
  APP_LOGO: '/assets-mobile/images/logos/logo.png',
  APP_LOGO_SUCCESS: '/assets-mobile/images/logos/logo-success.png'
};

const FLOW_V2_ANIMATION_FOLDER_BASE_URL = '/animations/FLOW_V2';
const VOI_V2_ANIMATION_FOLDER_BASE_URL = '/animations/VOI_V2';

export const ANIMATIONS = {
  FLOW_V2: {
    CARD: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_01_var_01.json`,
    PASSPORT: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_passport.json`,
    REVIEW: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_data.json`,
    FACE_ID: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_face_id.json`,
    BACK_CARD: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_back_card.json`,
    CLOSE_FAR: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_close_far.json`,
    LAPTOP: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_laptop.json`,
    LAMP: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_lamp.json`,
    PROOF_OF_ADDRESS: `${FLOW_V2_ANIMATION_FOLDER_BASE_URL}/anim_proof_of_address.json`
  },
  VOI_V2: {
    CARD: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_01_var_01.json`,
    PASSPORT: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_passport.json`,
    REVIEW: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_data.json`,
    FACE_ID: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_face_id.json`,
    BACK_CARD: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_back_card.json`,
    CLOSE_FAR: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_close_far.json`,
    LAPTOP: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_laptop.json`,
    LAMP: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_lamp.json`,
    CERTIFICATES: `${VOI_V2_ANIMATION_FOLDER_BASE_URL}/anim_certs.json`
  }
};
