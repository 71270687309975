import React from 'react';
import PropTypes from 'prop-types';
import { localizedString, isLocalizedStringDefined } from '@languages';
import Page from '@lib/components/v2/Page';
import Animation from '@components/Animation';
import {
  LOADING_SPINNER_SHOW_TOP_HEADER,
  LOADING_SPINNER_ANIMATION_URL,
  LOADING_SPINNER_ANIMATION_SIZE_SCALE,
  LOADING_SPINNER_HIDE_HEAD_MESSAGES
} from '@spotMobileConfig';

import classes from './LoadingSpinner.style.module.scss';

const LoadingSpinner = ({ heading, showTitle }) => {
  const Wrapper = LOADING_SPINNER_SHOW_TOP_HEADER
    ? Page
    : ({ children }) => (
        <div className={classes.wrapper_default}>
          <div className={classes.content}> {children}</div>
        </div>
      );

  return (
    <div className={classes.wrapper}>
      <Wrapper forceFillViewPort>
        {!LOADING_SPINNER_ANIMATION_URL && <div className={classes.spinner1} />}
        {!LOADING_SPINNER_HIDE_HEAD_MESSAGES && (
          <>
            <div className={classes.heading}>{heading}</div>
            <div className={classes.title}>
              {localizedString('thisMayTakeAFewSeconds')}
              <br />
              <br />
              {showTitle && localizedString('nextWeWillAskIfTheInformationShownMatchesYourPhotoID')}
            </div>
          </>
        )}
        {LOADING_SPINNER_ANIMATION_URL && (
          <div className={classes['animation-container']} data-testid="id-animation">
            <Animation
              scale={LOADING_SPINNER_ANIMATION_SIZE_SCALE}
              animationUrl={LOADING_SPINNER_ANIMATION_URL}
            />
          </div>
        )}

        {isLocalizedStringDefined('loadingSpinner.FOOTER_MESSAGE') && (
          <div className={classes.footer}>{localizedString('loadingSpinner.FOOTER_MESSAGE')}</div>
        )}
      </Wrapper>
    </div>
  );
};

LoadingSpinner.propTypes = {
  heading: PropTypes.string,
  showTitle: PropTypes.bool
};

LoadingSpinner.defaultProps = {
  heading: localizedString('pleaseWait'),
  showTitle: false
};

export default LoadingSpinner;
