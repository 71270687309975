import parse from 'html-react-parser';
import { isLocalizedStringDefined, localizedString } from '@languages';
import { NoMobileDeviceWrapper } from '@layouts/NoMobileDeviceWrapper';

export const OnlyMobileAllowed = () => {
  const title = isLocalizedStringDefined('desktop.ONLY_MOBILE_ALLOWED_TITLE')
    ? localizedString('desktop.ONLY_MOBILE_ALLOWED_TITLE')
    : '';

  return (
    <NoMobileDeviceWrapper title={title}>
      <div className="p-4 text-center">
        {parse(localizedString('desktop.ONLY_MOBILE_ALLOWED_CONTENT'))}
      </div>
    </NoMobileDeviceWrapper>
  );
};
