/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../../styles/main.scss';
import Message from '@lib/components/v2/Message';

import { Success } from '@Q_ONLY_FLOW/components';
import Modal from '@lib/components/v2/Modal';
import { Questionnaire } from '@Q_ONLY_FLOW/containers';
import { isAndroidDevice, isBrowserPermitted, setCookie, getCookie } from '@js/lib/Utils';
import { localizedString } from '@languages';
import { DeviceIncompatibleOpenChrome } from '@FLOW_V2_FLOW/errors';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tenMinsLeft: false,
      completed: false,
      isLandscape: false,

      redirect: false
    };
  }

  componentDidMount() {
    const { isCompleted: completed } = this.props;
    this.setState({ completed });

    const transToken = document.querySelector('body').getAttribute('data-id');
    const storedTransToken = getCookie('transToken');

    if (transToken === storedTransToken) {
      if (getCookie('_permission') === 1) {
        // Capturing ID
        setCookie('_permission', 0, -7);
      } else if (getCookie('_permission') === 2) {
        // Liveness step
        setCookie('_permission', 0, -7);
      }
    } else {
      setCookie('transToken', transToken, -7);
    }

    // Landscape
    const isLandscape = window.innerHeight < window.innerWidth;
    this.setState({ isLandscape });
    window.addEventListener('orientationchange', () => {
      this.setState(({ isLandscape }) => ({ isLandscape: !isLandscape }));
    });

    // Start the timeout for 1 hour.
    let countdown = 60 * 60;
    const timerId = setInterval(() => {
      if (countdown === 600) {
        this.setState({
          tenMinsLeft: true
        });
      }

      if (countdown <= 0) {
        this.setState({
          tenMinsLeft: false
        });
        clearInterval(timerId);
      }
      countdown--;
    }, 1000);
  }

  /**
   * Unbind the event listener
   */
  componentWillUnmount() {
    window.removeEventListener('orientationchange', () => {
      this.setState(({ isLandscape }) => ({ isLandscape: !isLandscape }));
    });
  }

  /**
   * Render the component's.
   *
   * @return {ReactElement}
   */
  render() {
    const { questionnaire } = this.props;
    const { tenMinsLeft, completed, isLandscape, redirect } = this.state;

    /**
     * Button states
     */
    const largeShadow = { large: true, shadow: true };
    const openChromeBtns = [
      {
        label: localizedString('proceed'),
        ...largeShadow,
        onClick: () => {
          const { id } = document.body.dataset;
          const url = `googlechrome://navigate?url=${document.location.href}${id}`;
          document.location = url;
        }
      }
    ];

    const tenMinsLeftBtns = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ tenMinsLeft: false })
      }
    ];

    // Now it's a mobile device. Check if Browser is Firefox, as Firefox is not permitted for mobile flow
    if (isAndroidDevice() && !isBrowserPermitted()) {
      return <DeviceIncompatibleOpenChrome buttons={openChromeBtns} />;
    }

    return (
      <div>
        {isLandscape && <Message landscope />}

        {!completed && (
          <Questionnaire
            questionnaire={questionnaire}
            onComplete={() => this.setState({ completed: true })}
          />
        )}

        {tenMinsLeft && (
          <Modal isOpen issue={localizedString('tenMinsLeftDesc')} buttons={tenMinsLeftBtns} />
        )}
        {completed && <Success redirect={redirect} />}
      </div>
    );
  }
}

App.propTypes = {
  isCompleted: PropTypes.bool,
  questionnaire: PropTypes.object
};

export default connect(mapStateToProps, null)(App);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, appConfig }) {
  return {
    idDetails: information.idDetails,
    addresses: information.addresses,
    appConfig
  };
}
