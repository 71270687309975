import moment from 'moment';
import { DOCUMENTS } from '@js/lib/constants/documents';

export const isTimeLimitOKForDigitalScreenShot = ({
  cardType,
  refreshedDate,
  refreshedTime
} = {}) => {
  const TIME_LIMIT_IN_MINUTES = 3;

  if (cardType !== DOCUMENTS.DIGITAL_DRIVER_LICENCE.cardType) {
    return true;
  }

  if (!refreshedDate || !refreshedTime) {
    return false;
  }

  const refreshedDateFormat = 'D-MM-YYYY';
  const refreshedTimeFormat = 'hh:mm a';
  const screenShotDate = moment(refreshedDate, refreshedDateFormat);
  const screenShotTime = moment(refreshedTime, refreshedTimeFormat);

  screenShotDate.add({ hours: screenShotTime.hours(), minutes: screenShotTime.minutes() });
  const minutesPassedSinceScreenShot = moment().diff(screenShotDate, 'minutes');

  return minutesPassedSinceScreenShot <= TIME_LIMIT_IN_MINUTES;
};
