import { getCountryLabelFromIso2Code } from '@lib/countryUtils';
import { localizedString } from '@languages';
import { FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 } from '@spotMobileConfig';

export const getResidentalAddressLabel = () => {
  if (FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2) {
    if (FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 === 'AU') {
      return 'Australian residential address';
    }
    return `${localizedString('residentalAddress')} (${getCountryLabelFromIso2Code(
      FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2
    )})`;
  }

  return localizedString('residentalAddress');
};
