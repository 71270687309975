import React from 'react';
import PropTypes from 'prop-types';
import Message from '@lib/components/v2/Message';

const Success = ({ redirect = false }) => {
  return (
    <Message completed title="Questionnaire complete" buttons={[]}>
      <h3 className="b-title">
        <b>Thank you for providing your personal information.</b>
        <br />
        <br />
        <b>Next steps</b>
        <br />
        <br />
        Your details have been received and will now be reviewed. If anything further is required,
        one of our staff will be in touch to advise and guide you accordingly.
        <br />
        <br />
        You can close this window
      </h3>
      <br />
      {redirect && <div>You will now be redirected.</div>}
    </Message>
  );
};

Success.propTypes = {
  redirect: PropTypes.bool
};

export default Success;
